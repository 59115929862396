<template>
  <section>
    <breadcrumb :title="$t('agents')" :subtitle="$t('new_user')"/>
    <div class="is-flex is-flex-direction-column form-agent">
      <div class="is-flex ml-60 ">
        <b-tag type="is-green-success" class="width-110" rounded>{{ $t('credentials') }}</b-tag>
      </div>
      <div class="columns mt-20">
        <div class="column is-half is-flex is-justify-content-center is-align-items-center">
          <div class="columns is-flex-direction-column is-flex">
            <div class="column is-half is-align-items-center is-flex-direction-column">
              <div v-if="!avatarPreview" @click="toggleShow" class="rounded-50x avatar cursor-pointer">
                <span class="mdi mdi-account-circle account-avatar"></span>
              </div>
              <div @click="toggleShow" v-else class="cursor-pointer">
                <img class="avatar-load" :src="avatarPreview" alt="">
              </div>
              <b-field hidden>
                <b-upload ref="pic" drag-drop native v-model="avatar"></b-upload>
              </b-field>
            </div>
            <div class="column is-half is-flex is-flex-direction-column">
              <span class="help">{{ $t('avatar') }}</span>
              <span class="help">{{ $t('avatar_help') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <b-field :label="`${$t('tenant')}*`">
            <b-input type="text" v-model="admin.tenant" placeholder="" custom-class="full-input"></b-input>
          </b-field>
          <b-field :label="`${$t('email')}*`">
            <b-input type="text" v-model="admin.email" placeholder="" custom-class="full-input"></b-input>
          </b-field>
          <b-field :label="`${$t('password')}*`">
            <b-input type="text" v-model="admin.password" placeholder="" custom-class="full-input"></b-input>
          </b-field>
          <section v-if="pass_val && admin.password" class="is-flex is-flex-direction-column">
            <password-required/>
          </section>
          <b-field v-if="admin.password===null" :label="`${$t('valid_password')}*`">
            <b-input type="text" placeholder="" v-model="verify_password" custom-class="full-input"></b-input>
          </b-field>
          <b-field v-else-if="admin.password !== verify_password && verify_password!==null"
                   :label="`${$t('valid_password')}*`" type="is-danger" :message="$t('pass_error')">
            <b-input type="text" placeholder="" v-model="verify_password" custom-class="full-input"></b-input>
          </b-field>
          <b-field v-else :label="`${$t('valid_password')}*`" type="is-success" :message="$t('pass_ok')">
            <b-input type="text" placeholder="" v-model="verify_password" custom-class="full-input"></b-input>
          </b-field>
        </div>
      </div>
      <div class="is-flex is-justify-content-flex-end">
        <b-button class="btn btn-back mx-10">
          {{ $t('back') }}
        </b-button>
        <b-button class="btn btn-continue mx-10" type="is-primary">
          {{ $t('save') }}
        </b-button>
      </div>
    </div>
  </section>
</template>
<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import PasswordRequired from "@/components/modals/password-required";

export default {
  name: "newUserComponent",
  components: {PasswordRequired, Breadcrumb},
  data() {
    return {
      pass_val: false,
      avatarPreview: null,
      avatar: null,
      verify_password: null,
      admin: {
        tenant: null,
        email: null,
        password: null,
        profilePicture: null
      }
    }

  },
  watch: {
    'admin.password': function () {
      let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/;
      if (!regex.test(this.admin.password)) {
        this.pass_val = true
      } else {
        this.pass_val = false
      }
    },
    avatar: async function () {
      this.avatarPreview = await this.getImgUrl(this.avatar)
    },
  },
  methods: {
    toggleShow() {
      this.$refs.pic.$el.click()
    },
    getImgUrl(file) {
      return new Promise(resolve => {
        if (file !== undefined) {
          const fr = new FileReader();
          fr.readAsDataURL(file);
          fr.addEventListener('load', () => {
            resolve(fr.result)
          })
        }
      })

    },
  }
}
</script>

<style lang="scss" scoped>
.help {
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #C4C4C4;
}

.avatar {
  height: 300px;
  width: 300px;
  background: #DEDDDD;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  margin-right: 0 !important;
}

.account-avatar {
  font-size: 200px;
}
</style>

